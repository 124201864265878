<template>
  <div>
    <div class="custom-card-box">
      <a-row :gutter="12">
        <a-col :span="8">
          <a-descriptions :column="1" class="custom-descriptions custom-descriptions-max-7">
            <a-descriptions-item label="产品名称">{{ product_info.name }}</a-descriptions-item>
            <a-descriptions-item label="是否惠民赠送">{{ product_info.str_benefit_the_people }}</a-descriptions-item>
            <a-descriptions-item label="规格数量">{{ product_info.item_count }}</a-descriptions-item>
            <a-descriptions-item label="销售渠道">{{ product_info.str_sales_channel }}</a-descriptions-item>
            <a-descriptions-item label="创建时间">{{ product_info.created_at }}</a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="6">
          <a-descriptions :column="1" class="custom-descriptions">
            <a-descriptions-item label="型号">{{ product_info.product_model }}</a-descriptions-item>
            <a-descriptions-item label="项目代码">{{ product_info.code }}</a-descriptions-item>
            <a-descriptions-item label="拼音码">{{ product_info.pinyin }}</a-descriptions-item>
            <a-descriptions-item label="销售范围">{{ product_info.str_sales_scope }}</a-descriptions-item>
            <a-descriptions-item label="产品介绍">{{ product_info.remark }}</a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="8">
          <a-descriptions :column="1" class="custom-descriptions custom-descriptions-max-9">
            <a-descriptions-item label="类型">{{ product_info.product_type }}</a-descriptions-item>
            <a-descriptions-item label="产生任务">{{ product_info.str_tasks }}</a-descriptions-item>
            <a-descriptions-item label="是否回传到穿衣间">{{ product_info.to_dress_room_display }}</a-descriptions-item>
            <a-descriptions-item label="状态">{{ product_info.effective? '有效':'无效' }}</a-descriptions-item>
            <a-descriptions-item label="图片">
              <template v-if="product_info.images === null" />
              <div class="custom-flex custom-flex-wrap">
                <img class="set-img" v-for="(item, index) in product_info.images" :key="index" :src="item.url" @click="showImg(product_info)">
              </div>
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
    </div>
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
    <div class="custom-card-box" style="margin-top: 20px;">
      <product-specification-list />
    </div>
  </div>
</template>

<script>
import ProductSpecificationList from '@/views/products/products_specifications/index'
import { findProductInfo } from '@/api/product'
import PreviewImage from '@/components/PreviewImage'

export default {
  name: 'ShowSystemProductInfo',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ProductSpecificationList,
    PreviewImage
  },
  data() {
    return {
      product_info: {},
      previewVisible: false,
      previewImages: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findProductInfo(this.$route.params.id).then((res) => {
        if (res.code === 0) {
          this.product_info = res.data
        }
      })
    },
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    }
  }
}
</script>
<style lang="less" scoped>
.set-img{
  width: 50px;
  height: 50px;
  margin: 5px;
}
</style>

